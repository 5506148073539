@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.homepage-container {
    display: flex;
    flex-direction: column;
    height: auto;
    background: linear-gradient(
        -45deg,
        #ffffff,
        #ffe4e9,
        #fcc8e2,
        #f6baed,
        #efb0ff,
        #d3a2ff,
        #8eb8ff,
        #91efff,
        #d5f9ff
    );
    background-size: 400% 400%;
    animation: gradient 9s ease infinite;
    height: auto;
    min-height: auto;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 0;
    font-optical-sizing: auto;
    font-style: normal;
}

.main-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
    margin-top: 5rem;
}

.main-content .content {
    max-width: 600px;
    padding: 0 20px;
}

.content p {
    font-size: 1.4rem;
}

.right-content {
    display: flex;
    justify-content: space-around; /* Align content to the right */
}

.right-content img {
    width: 80%; /* or any size you want */
    height: auto;
}

.bottom-section::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    background-color: #e5e5e5;
    z-index: -1;
}

.bottom-section {
    position: relative;
}

.bottom-section {
    position: relative;
    margin: 0;
}

.bottom-wrapper {
    position: relative;
    padding-top: 90px; /* Equal to the height of .bottom-shape */
}

/* Styles for the bottom shape */
.bottom-shape {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 200px;
    background: #e5e5e5;
    clip-path: ellipse(70% 50% at 50% 100%);
}

.bottom-content {
    position: relative;
    margin-bottom: -100px; /* Adjust this value as needed */
    background-color: #e5e5e5;
}

.bottom-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: teal;
    color: white;
    max-width: 600px; /* Adjust this value as needed */
    margin: auto;
    padding: 8rem;
    border-radius: 4rem;
    margin-bottom: 5rem;
}

.bottom-item h2,
.bottom-item p {
    margin: 0;
    padding: 0;
}

.bottom-item p {
    margin-top: 2rem;
    font-size: 1.4rem;
    text-align: justify;
}

.bottom-icon {
    font-size: 8rem;
    margin-bottom: 2rem;
}

.signup-button {
    background-color: rgb(0, 101, 255) !important;
    color: rgb(255, 255, 255) !important;
}

.signup-button:hover {
    background-color: #0747a6 !important;
}

.space {
    height: 8rem;
    background-color: #e5e5e5;
}

.footer {
    background-color: #8eb8ff;
    font-size: 2rem;
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;
    padding: 2rem;
}

.footer a {
    color: black;
}

.footer-icon {
    cursor: pointer;
}

.copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8eb8ff;
    padding: 0;
    margin: 0;
    border-top: 1px solid black;
}

@media (max-width: 1000px) {
    .main-content {
        flex-direction: column;
    }

    .right-content {
        margin-top: 5rem;
    }
}

@media (max-width: 676px) {
    .bottom-item {
        max-width: 80vw;
        padding: 5rem;
    }

    .bottom-item p {
        font-size: 1.2rem;
        text-align: left;
    }

    .bottom-item h2 {
        font-size: 1.6rem;
    }

    .bottom-icon {
        font-size: 4rem;
    }
}
