.modal-background-images {
    display: flex;
    flex-wrap: wrap;
}

.modal-background {
    height: 4rem;
    width: 6rem;
    margin: 0.4rem;
    border-radius: 10px;
    cursor: pointer;
    transition:
        border 0.2s ease,
        transform 0.2s ease;
    background-size: cover;
}

.modal-background:hover {
    border: 3px solid teal;
    transform: scale(1.1);
}

.modal-background.selected {
    border: 3px solid teal;
    transform: scale(1.1);
}

.modal-dropdown-item {
    width: 100%;
}

.file-upload-label {
    margin: 0.5rem 0 0.5rem 0;
}

.modal-input {
    background-color: #1d2125 !important;
    outline: teal;
    color: #9fadbc !important;
}

.modal-input:focus:not(:hover) {
    background-color: #33373a;
    color: #9fadbc;
}

.modal-input:hover {
    background-color: #33373a;
    border: 1px solid teal;
    color: #9fadbc;
}

/* Custom styles for the dropdown button */
.modal-dropdown-button {
    width: 100%;
    border: 1px solid #9fadbc;
}

.modal-delete-text {
    font-size: 1.5rem;
    margin: 0.5rem 1rem 1rem 1rem;
}

.modal-delete-buttons {
    display: flex;
    justify-content: space-around;
    margin: 1.5rem 0 0 0;
}

.modal-delete-button {
    min-width: 5rem;
}

.members-workspace-member-items {
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.members-workspace-member-items:hover {
    color: teal;
}

.members-workspace-member {
    margin: 0;
    padding: 0;
}

.members-workspace-member {
    padding: 0.6rem 0 0.6rem 0;
}

.browse-images {
    background-color: #33373a;
    border: 1px solid teal;
    border-radius: 0.5rem;
    color: #9fadbc;
    font-size: 0.8rem;
}
