@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');

.container {
    border-radius: 1rem;
}

.auth-shadow {
    background: white !important;
    box-shadow:
        0 30px 60px rgba(0, 0, 0, 0.3),
        /* Increase the spread radius and blur radius */ 0 10px 20px
            rgba(0, 0, 0, 0.2); /* Increase the spread radius and blur radius */
}

.viewport-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(
        -45deg,
        #ffffff,
        #ffe4e9,
        #fcc8e2,
        #f6baed,
        #efb0ff,
        #d3a2ff,
        #8eb8ff,
        #91efff,
        #d5f9ff
    );
    background-size: 400% 400%;
    animation: gradient 9s ease infinite;
    height: auto; /* Set to auto */
    min-height: 100vh; /* Ensure the content stretches to at least the full viewport height */
    overflow: hidden; /* Hide overflowing content below the bottom shape */
    position: relative; /* Set to relative */
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.cookie-notice {
    display: flex;
    align-self: center;
    justify-content: center;
    text-justify: auto;
    font-size: 0.7rem;
    padding: 0 1rem 0 1rem;
    margin: 0 2rem 0 2rem;
}

@media (max-width: 780px) {
    .container {
        width: 90vw !important;
    }

    .form {
        padding: 1rem;
    }
}
