.profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #33373a;
    color: #9fadbc;
    padding: 5rem;
}

.back-button {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.profile-title {
    text-align: center;
    border-bottom: 0.1px solid #9fadbc;
    margin-bottom: 1.4rem;
}

.profile-content {
    width: 35vw;
}

.profile-input {
    background-color: #1d2125 !important;
    outline: teal;
    color: #9fadbc !important;
    padding-right: 2rem;
    margin-bottom: 0.5rem;
}

.profile-input::placeholder {
    color: #9fadbc;
}

.profile-input:focus:not(:hover) {
    background-color: #33373a;
    color: #9fadbc;
}

.profile-input:hover {
    background-color: #33373a;
    border: 1px solid teal;
    color: #9fadbc;
}

.bio-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.emoji-toggle {
    position: absolute;
    top: 1px;
    right: 10px;
    cursor: pointer;
}

.emoji-picker {
    position: absolute;
    top: 0;
    left: calc(100% + 5px);
    z-index: 1;
}

.emoji-picker-wrapper {
    position: absolute;
    left: 101%;
    top: -50%;
    z-index: 1;
}

.profile-buttons {
    display: flex;
    justify-content: flex-end;
}

.danger-zone {
    margin-top: 1.4rem;
    border-top: 0.1px solid #9fadbc;
    text-align: center;
}

.danger-zone > h2 {
    margin-top: 1.2rem;
}

.danger-zone-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.3px solid #b91919;
}

.danger-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
}

/* Apply border bottom to all elements except the last one */
.danger-section:not(:last-child) {
    border-bottom: 0.1px solid #9fadbc;
}

.left-content {
    flex-grow: 1;
}

.left-content p {
    margin: 0;
    padding: 0;
    text-align: start;
}

.danger-button {
    margin-left: 3rem;
}

/* Hide the scrollbar */
::-webkit-scrollbar {
    display: none;
}
