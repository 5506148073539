.board-page {
    background-color: #33373a;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.board-container {
    width: 100%;
    min-height: calc(100vh - 45px - 4rem - 0.6rem - 45px);
    background-size: cover;
    background-position: center;
}

.board-content {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
}

.board-content-items {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 45px - 4rem - 0.6rem - 45px);
    margin: 4rem 4rem 0 4rem;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
}

.lists-container {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}

.new-button {
    display: flex;
    align-items: center;
    background: rgba(144, 140, 140, 0.6);
    backdrop-filter: blur(6px);
    color: white;
    width: 15rem;
    border-radius: 0.7rem;
    padding: 0.4rem;
    cursor: pointer;
    margin-left: 1rem;
}

.new-button:hover {
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(6px);
}

.new-button > p {
    margin: 0;
}

.new-list {
    min-width: 15rem;
    background: rgba(0, 0, 0, 0.9);
    color: #9fadbc;
    border-radius: 0.7rem;
    padding: 0.2rem;
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.new-list-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0.3rem 0.3rem 0 0.3rem;
    padding: 0.3rem;
    font-weight: 700;
}

.new-list-top > input {
    min-width: 100%;
    background-color: #3d3941;
    border-radius: 0.3rem;
    border: none;
    color: #9fadbc;
    padding-left: 0.3rem;
}

.new-list-top > input:hover,
.new-list-top > input:focus {
    border: 1px solid teal;
    outline: none;
}

.new-list-bottom {
    margin: 0.3rem 0.3rem 0 0.3rem;
    padding: 0.3rem 0.3rem 0.3rem 0;
    border-radius: 0.3rem;
    display: flex;
    font-size: 1rem;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
}

.new-list-button {
    margin: 0;
    padding: 0.2rem 0.5rem 0.2rem 0.3rem;
}

.new-list-cancel {
    margin-left: 0.5rem;
    padding: 0.3rem;
    font-size: 1.6rem;
    border-radius: 0.3rem;
}

.new-list-cancel:hover {
    background-color: #3d3941;
}

.new-card-list-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.new-card-list-bottom input {
    box-sizing: border-box;
    margin: 0.2rem;
    padding: 0.3rem 0.3rem 0.3rem 0.5rem;
    border-radius: 0.3rem;
    display: flex;
    font-size: 1rem;
    flex-direction: column;
    background-color: #3d3941;
    height: 2.4rem;
    width: calc(100% - 0.6rem);
    border: none;
    color: #9fadbc;
}

.new-card-list-bottom input:hover,
.new-card-list-bottom input:focus {
    border: 1px solid teal;
    outline: none;
}

.new-card-list-bottom input::placeholder,
.new-list-top input::placeholder {
    color: #9fadbc;
}

.button-icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 0.6rem);
    margin: 0.2rem;
    padding: 0.3rem 0.3rem 0.3rem 0.5rem;
    box-sizing: border-box;
}

.list-dropdown-menu {
    background-color: #272b2b !important;
}

.boardpage-mobile {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    background: linear-gradient(
        -45deg,
        #ffffff,
        #ffe4e9,
        #fcc8e2,
        #f6baed,
        #efb0ff,
        #d3a2ff,
        #8eb8ff,
        #91efff,
        #d5f9ff
    );
    background-size: 400% 400%;
    animation: gradient 9s ease infinite;
}

.boardpage-mobile p {
    margin: 2rem;
    padding: 2rem;
}

.boardpage-mobile button {
    position: fixed;
    top: 20px;
    left: 20px;
    background-color: transparent;
    border-radius: 0.4rem;
    size: 8rem;
    border: 1px solid black;
    color: black;
}
