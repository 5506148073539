.home {
    background-color: #33373a;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.home-container {
    display: flex;
    flex: 1;
    margin: 50px;
    width: 100vw;
}

.home-sidebar {
    display: flex;
    width: 10vw; /* Change this */
}

.home-sidebar > p {
    width: 100%;
    padding: 5px 5px 5px 20px;
    margin: 5px;
    border-radius: 10px;
}

.home-sidebar > p:hover {
    background-color: #474b4d;
}

.selected {
    background-color: #223e66 !important;
    color: #579dff;
}

.home-content {
    flex: 2;
    margin-left: 30px;
    position: relative;
}

.boards {
    margin-bottom: 30px;
}

.board-content-title {
    color: #9fadbc;
    font-size: 20px;
    margin-top: 30px;
}

.board-content-title-icon {
    margin-right: 8px;
}

.board-content {
    display: flex;
    flex-wrap: wrap;
}

.board {
    position: relative;
    width: 250px !important;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    margin: 0px 10px 10px 10px;
    /* flex: 0 0 calc(30% - 10px); */
}

.board-img {
    width: 100% !important;
    height: 100%;
    object-fit: cover;
}

.board:hover .board-img {
    opacity: 0.7; /* Reduce opacity on hover */
}

.board-title {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    font-weight: 900;
    font-size: 16px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 0 !important;
}

.star-icon {
    color: white;
    font-size: 18px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    opacity: 0;
    transition:
        transform 0.3s ease,
        opacity 0.3s ease,
        font-size 0.1s ease;
}

.star-icon:hover {
    font-size: 20px;
    font-weight: bold;
}

.board:hover .star-icon {
    transform: translate(-50%, -50%);
    opacity: 1;
}

.star-full {
    color: #e5c100;
    font-size: 18px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    opacity: 1;
    transition: font-size 0.1s ease;
}

.star-full:hover {
    font-size: 20px;
}

.workspace {
    width: calc(100% - 20px); /* Adjust the width as needed */
    max-width: 70vw; /* Limit the maximum width */
    margin-bottom: 1rem;
}

.workspace-settings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.workspace-title {
    color: #9fadbc;
    font-size: 20px;
    font-weight: 900;
}

.workspace-buttons {
    display: flex;
    flex-direction: row;
    margin-right: 150px;
}

.button {
    margin-left: 10px;
    height: 30px;
    font-size: 13px;
    background-color: #474b4d !important;
}

.button:hover {
    background-color: #595d5e !important;
}

.settings-icon {
    margin-right: 5px;
}

.board-content {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 780px) {
    .home-container {
        align-content: center;
        min-width: 100vw;
        justify-content: space-between;
    }

    .dropdown-btn {
        margin: 0;
    }

    .home-sidebar {
        display: none;
    }

    .home-content {
        min-width: 100vw;
    }
}
