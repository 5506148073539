.board {
    position: relative;
    width: auto !important;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    margin: 0px 10px 10px 10px;
    /* flex: 0 0 calc(30% - 10px); */
}

.board-img {
    width: 100% !important;
    height: 100%;
    object-fit: cover;
}

.board:hover .board-img {
    opacity: 0.4; /* Reduce opacity on hover */
}

.board-title {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    font-weight: 900;
    font-size: 16px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 0 !important;
}

.workspace-name {
    position: absolute;
    top: 30px;
    left: 10px;
    color: white;
    font-weight: 900;
    font-size: 11px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 0 !important;
}

.star-icon {
    color: white;
    font-size: 18px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    opacity: 0;
    transition:
        transform 0.3s ease,
        opacity 0.3s ease,
        font-size 0.1s ease;
}

.star-icon:hover {
    font-size: 20px;
    font-weight: bold;
}

.board:hover .star-icon {
    transform: translate(-50%, -50%);
    opacity: 1;
}

.star-full {
    color: #e5c100;
    font-size: 18px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    opacity: 1;
    transition: font-size 0.1s ease;
}

.star-full:hover {
    font-size: 20px;
}
