.workspace {
    width: calc(100% - 20px); /* Adjust the width as needed */
    max-width: 70vw; /* Limit the maximum width */
}

.workspace-settings {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-bottom: 1px solid #9fadbc;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.workspace-title {
    color: #9fadbc;
    font-size: 20px;
    font-weight: 900;
}

.workspace-buttons {
    display: flex;
    flex-direction: row;
    margin-right: 0;
}

.button {
    margin-left: 10px;
    height: 30px;
    font-size: 13px;
    background-color: #474b4d !important;
    color: #9fadbc;
}

.button:hover {
    background-color: #595d5e !important;
    color: #9fadbc;
}

.settings-icon {
    margin-right: 5px;
}

.board-content {
    display: flex;
    flex-wrap: wrap;
}

.input-workspace-name {
    color: #9fadbc;
    font-size: 20px;
    font-weight: 900;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    margin-bottom: 1rem;
    text-decoration: underline;
}

.settings-dropdown-icons {
    margin-right: 0.4rem;
}

@media (max-width: 652px) {
    .workspace-buttons button {
        width: 7rem;
        margin-bottom: 1rem;
    }
}
