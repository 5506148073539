.navbar-brand img {
    height: 60px;
    width: auto;
}

.GetStartedButton {
    background-color: rgb(0, 101, 255);
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    height: 100%;
    padding: 0.5rem 1.5rem;
    text-decoration: none;
}

.GetStartedButton:hover {
    background-color: #0747a6;
}

.LoginButton {
    display: flex;
    color: black;
    align-items: center;
    font-size: 1.2rem;
    height: 100%;
    padding: 0.5rem 1.5rem;
    text-decoration: none !important;
    margin-right: 15px;
}

@media (max-width: 1000px) {
    .navbar {
        padding: 0 10px;
    }

    .navbar-brand img {
        height: 50px;
    }

    .GetStartedButton,
    .LoginButton {
        padding: 0.5rem 1rem;
    }
}

@media (max-width: 576px) {
    .navbar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 0;
        min-height: 70px; /* Adjust this value as needed */
    }

    .navbar-brand {
        order: 1;
    }

    .navbar .d-flex {
        order: 2;
    }

    .GetStartedButton,
    .LoginButton {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }
}

@media (max-width: 470px) {
    .GetStartedButton,
    .LoginButton {
        width: 5rem;
        justify-content: center;
        text-align: center;
    }

    .navbar-brand img {
        max-height: 45px;
    }
}

@media (max-width: 390px) {
    .navbar-brand img {
        max-height: 40px;
        margin: 0;
        padding: 0;
    }

    .GetStartedButton,
    .LoginButton {
        width: 4rem;
        margin: 0;
        padding: 0;
    }
}
